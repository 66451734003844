
    
    body {
        font-family: 'Lato', sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.6em;
        color: #656565;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    a {
        color: #696969;
    }
    
    .clear {
        clear: both;
    }

    .nav-pills-custom .nav-link {
        color: #000;
        background: #fff;
        position: relative;
        font-weight: 700 !important;
        font-size: 12px;
    }
    
    .nav-pills-custom .nav-link.active {
        color: #000;
        background: #fff;
        font-weight: 700 !important;
    }
    
    .nav-pills-custom .nav-link.active::before {
        opacity: 1;
    }
    
    .nav-pills-custom .nav-link {
        color: #000;
        background: #fff;
        border-bottom: 1.5px solid #d5d0d0;
        border-radius: 0;
        font-size: 12px;
        letter-spacing: 1.3px;
        padding: 5px 0 5px !important;
        font-weight: 700;
    }
    
    .nav-pills-custom .nav-link.active {
        font-weight: 700;
    }
    
    .edit-button {
        border: 1px solid #000;
        background: transparent;
        border-radius: 0;
        padding: 4px 40px;
        width: 40%;
        margin-top: 20px;
    }
    
    .edit-button:hover {
        background-color: #000;
        color: #fff;
    }
    
    label {
        font-weight: 700;
        color: #000;
        letter-spacing: 1px;
    }
    
    input::placeholder {
        color: #000;
        letter-spacing: 1px;
    }
    
    h4 {
        color: #000;
    }
    
    .ui {
        letter-spacing: 1.2px;
        color: black;
    }
    
/* ************Devansh-CSS******************************************************* */
.nav-pills .nav-link.active{
    color: #fff !important;
    background-color: #8b8786 !important;
    width: 100%;
    margin-top: 10px;
}
.text-uppercase{
    /* padding: 5px 8px; */
}

    @media (min-width: 992px) {
        .nav-pills-custom .nav-link::before {
            content: '';
            display: block;
            border-top: 8px solid transparent;
            border-left: 10px solid #fff;
            border-bottom: 8px solid transparent;
            position: absolute;
            top: 50%;
            right: -10px;
            transform: translateY(-50%);
            opacity: 0;
        }
    }
    
    /* form {
        max-width: 500px;
        margin: auto;
        text-align: left;
    }*/
    
    p {
        font-size: 16px;
        margin-bottom: 10px;
    } 
    
    .edit-btn {
       
    } 
    
    